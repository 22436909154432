import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/Coaching/Intro"
import Banner from "../components/Commun/Banner"
import MesCoaching from "../components/Coaching/MesCoaching"
import Price from "../components/Commun/Price"
import Contact from "../components/Commun/Contact"

const Coaching = ({ data, location }) => {
  const prismicData = getPrismicData(data)
  return (
    <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
      <SEO
        title="Coaching Sportif Personnalisé"
        description="Découvrez ma méthode de coaching à Nantes ou à distance et atteignons ensemble votre objectif (perte de poids, réathlétisation, prise de masse...) !"
      />
      <Banner
        title={prismicData.banner.titre}
        imageBanner={prismicData.banner.image}
        imageHeight="350px"
        imageMobileHeight="350px"
        isfirstBlock
        marginBottom="0"
      />
      <Intro
        titre={prismicData.contenuPrincipal.titre}
        text={prismicData.contenuPrincipal.contenu}
        text2={prismicData.contenuPrincipal.contenu_colonne_2}
        coachingDistance={prismicData.coachingDistance.reassurances}
        coachingNantes={prismicData.coachingNantes.reassurances}
      />
      <MesCoaching
        padding="50px 0"
        coachingFemme={prismicData.coachingFemmes.reassurances}
        coachingHomme={prismicData.coachingHommes.reassurances}
      />
      <Price
        balance={data.balance.childImageSharp.fixed}
        tarifsNantes={prismicData.tarifsNantes.tarifs}
        offresNantesSolo={prismicData.offresNantesSolo.items}
        offresNantesDuo={prismicData.offresNantesDuo.items}
        tarifsDistance={prismicData.tarifsDistance.tarifs}
        uri={location.href}
      />
      <Contact />
    </Layout>
  )
}

export default Coaching

export const query = graphql`
  query {
    prismicCoachingBodyBanniere {
      primary {
        contenu {
          html
          text
        }
        image {
          fluid(maxWidth: 3000) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
    prismicCoachingBodyCoachingDistance {
      items {
        contenu {
          html
          text
        }
      }
    }
    prismicCoachingBodyCoachingNantes {
      items {
        contenu {
          html
          text
        }
      }
    }
    prismicCoachingBodyContenuPrincipal {
      primary {
        contenu {
          html
        }
        contenu_colonne_2 {
          html
        }
        titre {
          html
          text
        }
      }
    }
    prismicCoachingPourFemmeBodyCoaching {
      items {
        contenu {
          html
          text
        }
        image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        titre {
          html
          text
        }
        url {
          url
        }
      }
    }
    prismicCoachingPourHommeBodyCoaching {
      items {
        contenu {
          html
          text
        }
        image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        titre {
          html
          text
        }
        url {
          url
        }
      }
    }
    prismicCoachingPourHommeBodyTarifs {
      items {
        type
        contenu {
          html
          text
        }
        sous_titre {
          text
        }
        titre {
          html
          text
        }
        icone {
          url
          fixed(width: 50, height: 50) {
            ...GatsbyPrismicImageFixed
          }
        }
      }
    }
    prismicCoachingPourHommeBodyOffresNantes {
      primary {
        type
      }
      items {
        prix_e_commerce {
          text
        }
        description_e_commerce {
          text
        }
        id_e_commerce {
          text
        }
        offre_selection {
          text
        }
      }
    }
    prismicCoachingPourHommeBodyOffresNantesSolo {
      primary {
        type
      }
      items {
        prix_e_commerce {
          text
        }
        description_e_commerce {
          text
        }
        id_e_commerce {
          text
        }
        offre_selection {
          text
        }
      }
    }
    prismicCoachingPourHommeBodyTarifsDistance {
      items {
        contenu {
          html
          text
        }
        prix {
          html
          text
        }
        sous_titre {
          html
          text
        }
        titre {
          html
          text
        }
        icone {
          url
          fixed(width: 50, height: 50) {
            ...GatsbyPrismicImageFixed
          }
        }
        description_ecommerce {
          text
        }
        id_ecommerce {
          text
        }
      }
    }
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    balance: file(relativePath: { eq: "icones/scale.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

function getPrismicData(data) {
  return {
    banner: {
      titre: data.prismicCoachingBodyBanniere.primary.contenu.text,
      image:
        data.prismicCoachingBodyBanniere.primary.image.fluid,
    },
    coachingDistance: {
      reassurances: data.prismicCoachingBodyCoachingDistance.items,
    },
    coachingNantes: {
      reassurances: data.prismicCoachingBodyCoachingNantes.items,
    },
    coachingFemmes: {
      reassurances: data.prismicCoachingPourFemmeBodyCoaching.items,
    },
    coachingHommes: {
      reassurances: data.prismicCoachingPourHommeBodyCoaching.items,
    },
    contenuPrincipal: {
      titre: data.prismicCoachingBodyContenuPrincipal.primary.titre.text,
      contenu: data.prismicCoachingBodyContenuPrincipal.primary.contenu.html,
      contenu_colonne_2:
        data.prismicCoachingBodyContenuPrincipal.primary.contenu_colonne_2.html,
    },
    tarifsNantes: {
      tarifs: data.prismicCoachingPourHommeBodyTarifs.items,
    },
    offresNantesSolo: {
      items: data.prismicCoachingPourHommeBodyOffresNantes.items,
    },
    offresNantesDuo: {
      items: data.prismicCoachingPourHommeBodyOffresNantesSolo.items,
    },
    tarifsDistance: {
      tarifs: data.prismicCoachingPourHommeBodyTarifsDistance.items,
    },
  }
}
